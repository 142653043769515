import PropTypes from 'prop-types';
import { useEffect, createContext, useContext, useState, useRef } from 'react';
import EventEmitter from 'events';
import {signal} from "@preact/signals-react";

const initialState = {
    lastEmailSearchResults: null,
    lastUserQueryListResults: null,
    lastUserQueryOption: { value: null },
    lastUserQueryPageSize: { value: 5 },
    lastBuddyChallengeQueryListResults: null,
    lastBuddyChallengeQueryOption: { value: null },
    lastBuddyChallengeQueryPageSize: { value: 5 },
    lastNetworkRoomsResults: null,
    lastBuddyFinderPostListResults: null,
    lastBuddyFinderPostFilterOption: { value: null },
    lastBuddyFinderPostPageSize: { value: 10 },
    lastBuddyChallengeFinderStatusQueryListResults: null,
    lastBuddyChallengeFinderStatusQueryOption: { value: null },
    lastBuddyChallengeFinderStatusQueryPageSize: { value: 10 }
};

const DashboardSessionContext = createContext({
    initialState
});

DashboardSessionProvider.propTypes = {
    children: PropTypes.node,
};
  
function DashboardSessionProvider({ children }) {

    const lastEmailSearchResultsData = signal(null);

    const lastUserQueryListResults = signal(null);
    const lastUserQueryOption = { value: null };
    const lastUserQueryPageSize = { value: 5 };

    const lastBuddyChallengeQueryListResults = signal(null);
    const lastBuddyChallengeQueryOption = { value: null };
    const lastBuddyChallengeQueryPageSize = { value: 10 };

    const lastBuddyFinderPostListResults = signal(null);
    const lastBuddyFinderPostFilterOption = { value: null };
    const lastBuddyFinderPostPageSize = { value: 10 };

    const lastBuddyChallengeFinderStatusQueryListResults = signal(null);
    const lastBuddyChallengeFinderStatusQueryOption = { value: null };
    const lastBuddyChallengeFinderStatusQueryPageSize = { value: 10 };

    const lastNetworkRoomsResults = signal(null);

    return (

        <DashboardSessionContext.Provider
            value={{
                lastEmailSearchResultsData,
                lastUserQueryListResults,
                lastUserQueryOption,
                lastUserQueryPageSize,
                lastBuddyChallengeQueryListResults,
                lastBuddyChallengeQueryOption,
                lastBuddyChallengeQueryPageSize,
                lastNetworkRoomsResults,
                lastBuddyFinderPostListResults,
                lastBuddyFinderPostFilterOption,
                lastBuddyFinderPostPageSize,
                lastBuddyChallengeFinderStatusQueryListResults,
                lastBuddyChallengeFinderStatusQueryOption,
                lastBuddyChallengeFinderStatusQueryPageSize
            }}
        >
            
            {children}

        </DashboardSessionContext.Provider>
    );
}

export { DashboardSessionContext, DashboardSessionProvider };
