import PropTypes from 'prop-types';
import { useEffect, createContext, useContext, useState, useReducer, useRef } from 'react';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { get, ref } from 'firebase/database';
import { Snackbar, Alert } from '@mui/material';
import { AuthContext } from './FirebaseContext';

// config

// ----------------------------------------------------------------------

const initialState = {
    adminUsernames: [],
    avatars: {},
    banners: {},
    tags: {},
    items: {},
    leaderboards: {},
    events: {},
    buddySettings: {}
};

const ConfigContext = createContext({
    initialState
});

// ----------------------------------------------------------------------

ConfigProvider.propTypes = {
    children: PropTypes.node,
};
  
function ConfigProvider({ children }) {

    const authContext = useContext(AuthContext);

    const [DB, RTDB, isAuthenticated] = [authContext.DB, authContext.RTDB, authContext.isAuthenticated];

    const [initialized, setInitialized] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const usernameData = useRef(null);
    const avatarData = useRef(null);
    const bannerData = useRef(null);
    const tagData = useRef(null);
    const itemData = useRef(null);
    const leaderboardData = useRef(null);
    const eventData = useRef(null);
    const buddySettingsData = useRef(null);

    const inDashboard = window.location.pathname.includes('/dashboard');

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {

        if (!inDashboard) {
            // Make sure config doesnt load stuff when not in dashboard.. ex: home page..
            console.log("Not in dashboard. Will not load config data.");
            return;
        }

        if (!isAuthenticated) {
            console.log("Config data not loaded yet. Will wait for user to login.");
            return;
        }

        if (initialized)
            return;

        async function init() {

            console.log("getting data");

            const promises = [];

            const usernameRef = collection(DB, 'admin_users');
            const avatarRef = ref(RTDB, 'avatars');
            const bannerRef = ref(RTDB, 'banners');
            const tagRef = ref(RTDB, 'tags');
            const itemRef = ref(RTDB, 'items');
            const leaderboardRef = ref(RTDB, 'leaderboards');
            const eventRef = ref(RTDB, 'events');
            const buddySettingsRef = ref(RTDB, 'buddy_settings');

            promises.push(getDocs(usernameRef));
            promises.push(get(avatarRef));
            promises.push(get(bannerRef));
            promises.push(get(tagRef));
            promises.push(get(itemRef));
            promises.push(get(leaderboardRef));
            promises.push(get(eventRef));
            promises.push(get(buddySettingsRef));

            const [allUsers, allAvatars, allBanners, allTags, allItems, allLeaderboards, allEvents, buddySettings] = await Promise.all(promises);

            const allUsernames = {};
            
            allUsers.docs.forEach(doc => {
                allUsernames[doc.id] = doc.data();
            });

            usernameData.current = allUsernames;

            if (allAvatars.exists()) {
                avatarData.current = allAvatars.val();
            }

            if (allBanners.exists()) {
                bannerData.current = allBanners.val();
            }

            if (allTags.exists()) {
                tagData.current = allTags.val();
            }

            if (allItems.exists()) {
                itemData.current = allItems.val();
            }

            if (allLeaderboards.exists()) {
                leaderboardData.current = allLeaderboards.val();
            }

            if (allEvents.exists()) {
                eventData.current = allEvents.val();
            }

            if (buddySettings.exists()) {
                buddySettingsData.current = buddySettings.val();
            }
        }

        init().then(() => {

            console.log("Config data initialized successfully!");

            setInitialized(true);

            setSnackbarMessage("Config data initialized successfully!");
            setSnackbarSeverity('success');
            setSnackbarOpen(true);

        }).catch((error) => {

            console.log(`Error loading config data: ${error}`);

            setSnackbarMessage(`Error loading config data: ${error.message}`);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        });

    },[DB, RTDB, initialized, isAuthenticated]);

    return (
        <ConfigContext.Provider
        value={{
            adminUsernames: usernameData.current,
            avatars: avatarData.current,
            banners: bannerData.current,
            tags: tagData.current,
            items: itemData.current,
            leaderboards: leaderboardData.current,
            events: eventData.current,
            buddySettings: buddySettingsData.current
        }}
        >
            
        {children}

        <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                {snackbarMessage}
            </Alert>
        </Snackbar>

        </ConfigContext.Provider>
    );
}

export { ConfigContext, ConfigProvider };
