// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}
  
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
  
  // ----------------------------------------------------------------------
  
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  // register: path(ROOTS_AUTH, '/register'),
  // loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  // registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  // verify: path(ROOTS_AUTH, '/verify'),
  // resetPassword: path(ROOTS_AUTH, '/reset-password'),
  // newPassword: path(ROOTS_AUTH, '/new-password'),
};
  
export const PATH_PAGE = {
  privacy: '/privacy',
  page403: '/403',
  page404: '/404',
  page500: '/500',
};
  
export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: path(ROOTS_DASHBOARD, '/home'),
  messages: path(ROOTS_DASHBOARD, '/messages'),
  leaderboards: path(ROOTS_DASHBOARD, '/leaderboards'),
  networkRooms: path(ROOTS_DASHBOARD, '/network-rooms'),
  users: {
    root: path(ROOTS_DASHBOARD, '/users'),
    profile: (userId) => path(ROOTS_DASHBOARD, `/users/${userId}/profile`),
    matchHistory: (userId) => path(ROOTS_DASHBOARD, `/users/${userId}/match-history`),
    leaderboards: (userId) => path(ROOTS_DASHBOARD, `/users/${userId}/leaderboards`),
    buddyChallenge: (userId) => path(ROOTS_DASHBOARD, `/users/${userId}/buddy-challenge`)
  },
  events: {
    root: path(ROOTS_DASHBOARD, '/events'),
    reports: (eventId) => path(ROOTS_DASHBOARD, `/events/${eventId}/reports`),
  },
  buddyChallenge: path(ROOTS_DASHBOARD, '/buddy-challenge')
};
  